<template>
  <v-card class="" outlined>
    <v-card-title>
      <v-icon color="primary" class="mr-2">mdi-information-outline</v-icon>
      There are no <slot></slot> currently submitted.</v-card-title>
    <v-card-text class="pl-12">
      <slot></slot> from you website are sent to agentOS intray section.
      <p>For more information on setting up online features in your website please contact <a href="mailto:support@agentos.com" target="_blank">support@agentos.com</a></p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {

}
</script>

<style>

</style>