<template>
  <div>
    <v-container>
      <!-- Lettings valuations aka Lettings properties in $store -->
      <v-card flat tile>
        <div class="card-title-wrapper" id="sticky-nav">
          <v-card-title class="py-2 grey lighten-4 d-flex">
            <v-btn text class="text-center" @click="toDashboard">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <div class="mx-auto">
              <span class="headline font-weight-light">{{dataType}}</span>
              <p class="text-center mb-0 mt-n1 caption">
                <span v-if="returnDataByType.length > 1">{{ returnDataByType.length }} valuations</span>
                <span v-else-if="returnDataByType.length == 1">{{ returnDataByType.length }} valuation</span>
                <span v-else>no valuations</span>
              </p>
            </div>

          </v-card-title>
          <div class="white py-3 d-flex justify-center">
            <v-btn 
              @click="setDataType('Branch lettings valuations')" 
              :outlined="dataType !== 'Branch lettings valuations'"
              :class="{'white--text': dataType === 'Branch lettings valuations'}"
              class="mr-1 text-capitalize" 
              color="secondary"
            >
              <v-icon>mdi-account-group</v-icon>
            </v-btn>
            <v-btn 
              @click="setDataType('Branch completed')" 
              :outlined="dataType !== 'Branch completed'"
              :class="{'white--text': dataType === 'Branch completed'}"
              class="mr-5 text-capitalize" 
              color="secondary"
            >
              <v-icon>mdi-playlist-check</v-icon>
            </v-btn>
            <v-btn 
              @click="setDataType('My lettings valuations')" 
              :outlined="dataType !== 'My lettings valuations'" 
              :class="{'white--text': dataType === 'My lettings valuations'}"
              class="mr-1 text-capitalize" 
              color="primary"
            >
              <v-icon>mdi-account</v-icon>

            </v-btn>
            <v-btn 
              @click="setDataType('My completed')" 
              :outlined="dataType !== 'My completed'" 
              :class="{'white--text': dataType === 'My completed'}"
              class="text-capitalize" 
              color="primary"
            >
              <v-icon>mdi-playlist-check</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- Landlord Mr Glyn Trott has submitted on online valuation for a 2 bedroom house to rent at 10 Downing Street at £500pcm'.  
                Please access agentOS to import valuation. -->

        <v-card-text v-if="returnDataByType.length" class="px-0 pt-0 mb-0">
          <v-card class="px-1" flat outlined tile v-for="item in returnDataByType" :key="item.OID">
            <v-card-text>
              <div>
                <span v-if="item.Landlord.Surname">Landlord</span> 
                <span v-else>Lanlord (not specified)</span>
                <span v-if="item.Landlord.Surname">
                  <span v-if="item.Landlord.Title"> {{item.Landlord.Title}}</span> {{item.Landlord.Forename}} {{item.Landlord.Surname}}
                </span>
                has submitted an online valuation 
                <span v-if="item.BedroomCount"> for a <strong>{{item.BedroomCount}} bedroom </strong></span>
                <strong v-if="item.Type"> {{item.Type}}</strong>
                <span v-if="item.Address"> to rent at <strong>{{item.Address}}</strong></span>
                <span v-if="item.InstructionRentAdvertised"> at <strong>{{item.InstructionRentAdvertised | filterPrice}}.</strong></span>
                <br>Please access agentOS to import valuation
              </div>
              <div class="mt-5 text-right text-md-left">
                <div class="font-weight-light">Landlords details:</div>
                <div v-if="item.Landlord.EmailAddress" class="primary--text">{{item.Landlord.EmailAddress}}</div>
                <div v-if="item.Landlord.MobilePhone" class="primary--text">{{item.Landlord.MobilePhone}}</div>
              <div class="font-weight-light" v-if="!item.Landlord.EmailAddress && !item.Landlord.MobilePhone">n/a</div>
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-text class="mt-5" v-else>
          <NoDataMsg>
            Lettings valuations
          </NoDataMsg>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import NoDataMsg from '@/components/MessageComponents/Slots/NoDataMsg.vue';

export default {
  name: 'sales-properties',
  components: {
    NoDataMsg
  },
  data() {
    return {
      dataType: 'Branch lettings valuations'
    }
  },
  methods: {
    toDashboard() {
      this.$router.go(-1)
    },
    setDataType(type) {
      this.dataType = type
    }
  },
  computed: {
    ...mapState({
      branch: state => state.lettingsProperties.branch.data,
      branchCompleted: state => state.lettingsProperties.branchCompleted.data,
      staff: state => state.lettingsProperties.staff.data,
      staffCompleted: state => state.lettingsProperties.staffCompleted.data
    }),
    returnDataByType() {
      if(this.dataType === 'My lettings valuations') {return this.staff.Data}
      if(this.dataType === 'My completed') {return this.staffCompleted.Data}
      if(this.dataType === 'Branch completed') {return this.branchCompleted.Data}
      return this.branch.Data
    }
  },
  filters: {
    filterPrice(val) {
      return val.toLocaleString('en-UK', {style: 'currency', currency: 'GBP'}) 
    }
  } 
}
</script>

<style>

</style>