<template>
  <LettingsProperties />
</template>

<script>
import LettingsProperties from '@/components/MessageComponents/LettingsProperties.vue';

export default {
  name: 'lettings-properties-view',
  components: {
    LettingsProperties
  }
  ,
  created(){
    this.$store.commit("EXPIRE_LOGIN");
    if(this.$store.state.user === ''){
      this.$router.push('/');
    }
  }
}
</script>

<style>

</style>